var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1300px",
        "no-click-animation": "",
        scrollable: "",
        fullscreen: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { icon: "" },
                  on: { click: _vm.close },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _vm._v("Find Available Vehicles"),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "dateFilterform",
                              model: {
                                value: _vm.isDateFilterFormValid,
                                callback: function ($$v) {
                                  _vm.isDateFilterFormValid = $$v
                                },
                                expression: "isDateFilterFormValid",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Leave Date"),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("date-picker", {
                                            ref: "leaveDate",
                                            attrs: {
                                              label: "Date",
                                              rules: [
                                                () =>
                                                  !!_vm.leaveDate ||
                                                  "This field is required",
                                              ],
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.leaveDate,
                                              callback: function ($$v) {
                                                _vm.leaveDate = $$v
                                              },
                                              expression: "leaveDate",
                                            },
                                          }),
                                          _c("time-picker", {
                                            ref: "leaveTime",
                                            attrs: {
                                              label: "Time",
                                              rules: [
                                                () =>
                                                  !!_vm.leaveTime ||
                                                  "This field is required",
                                              ],
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.leaveTime,
                                              callback: function ($$v) {
                                                _vm.leaveTime = $$v
                                              },
                                              expression: "leaveTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Return Date"),
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("date-picker", {
                                            ref: "returnDate",
                                            attrs: {
                                              label: "Date",
                                              rules: [
                                                () =>
                                                  !!_vm.returnDate ||
                                                  "This field is required",
                                              ],
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.returnDate,
                                              callback: function ($$v) {
                                                _vm.returnDate = $$v
                                              },
                                              expression: "returnDate",
                                            },
                                          }),
                                          _c("time-picker", {
                                            ref: "returnTime",
                                            attrs: {
                                              label: "Time",
                                              rules: [
                                                () =>
                                                  !!_vm.returnTime ||
                                                  "This field is required",
                                              ],
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.returnTime,
                                              callback: function ($$v) {
                                                _vm.returnTime = $$v
                                              },
                                              expression: "returnTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex ma-2 justify-end" },
                            [
                              _c(
                                "div",
                                { staticClass: "mr-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.getItems },
                                    },
                                    [_vm._v(" Find Vehicles ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: { click: _vm.clearFilters },
                                    },
                                    [_vm._v(" Clear Filters ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.vehicleTypes,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Vehicle Type",
                                  outlined: "",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.filterList()
                                  },
                                },
                                model: {
                                  value: _vm.filterVehicleType,
                                  callback: function ($$v) {
                                    _vm.filterVehicleType = $$v
                                  },
                                  expression: "filterVehicleType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.vehicleLocations,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Vehicle Location",
                                  outlined: "",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.filterList()
                                  },
                                },
                                model: {
                                  value: _vm.filterVehicleLocation,
                                  callback: function ($$v) {
                                    _vm.filterVehicleLocation = $$v
                                  },
                                  expression: "filterVehicleLocation",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: { label: "w/ Wheelchair Lift" },
                                on: {
                                  change: function ($event) {
                                    return _vm.filterList()
                                  },
                                },
                                model: {
                                  value: _vm.showWithLift,
                                  callback: function ($$v) {
                                    _vm.showWithLift = $$v
                                  },
                                  expression: "showWithLift",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                ],
                1
              ),
              _vm._l(_vm.activeMessages, function (am) {
                return _c(
                  "v-row",
                  {
                    key: am.id,
                    staticClass: "d-flex justify-center",
                    attrs: { dense: "" },
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "10" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              type: am.alertType || "info",
                              text: "",
                            },
                          },
                          [_vm._v(_vm._s(am.message))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-row",
                { staticClass: "d-flex justify-center", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between flex-row",
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "Leave Date: " +
                                  _vm._s(
                                    _vm.readableDate(_vm.filterCopy.leaveDate)
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.readableTime(_vm.filterCopy.leaveTime)
                                  )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                " Return Date: " +
                                  _vm._s(
                                    _vm.readableDate(_vm.filterCopy.returnDate)
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.readableTime(_vm.filterCopy.returnTime)
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _c("v-simple-table", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("# Vehicles Unassigned"),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("Approved"),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v("Pending Approval"),
                                    ]),
                                  ]),
                                ]),
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [_vm._v(_vm._s(_vm.unassigned))]),
                                    _c("td", [_vm._v(_vm._s(_vm.approved))]),
                                    _c("td", [_vm._v(_vm._s(_vm.pending))]),
                                  ]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("v-data-table", {
                        attrs: {
                          "fixed-header": "",
                          headers: _vm.headers,
                          items: _vm.filteredAvailable,
                          "item-key": "id",
                          "no-data-text": "No vehicles available",
                          height: _vm.tableHeight,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.type`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.vehicleTypesById[item.type]
                                          ? _vm.vehicleTypesById[item.type].name
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.driver`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.vehicleTypesById[item.driverId]
                                          ? _vm.driversById[item.driverId]
                                              .firstName +
                                              " " +
                                              _vm.driversById[item.driverId]
                                                .lastName
                                          : item.driver || "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.cap`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.capacityElem) +
                                      "/" +
                                      _vm._s(item.capacityMid) +
                                      "/" +
                                      _vm._s(item.capacityHigh) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.location`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getVehicleLocation(item.id)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.lift`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(
                                          item.wheelchairLift
                                            ? "check"
                                            : "close"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { text: "" },
                  on: { click: _vm.close },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }