var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-badge",
    {
      staticClass: "full-width",
      attrs: {
        value: !!_vm.tripRequest.attention,
        color: "#ff5252",
        icon: "mdi-exclamation-thick",
        left: "",
        overlap: "",
        bordered: "",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "ma-0" },
        [
          _c(
            "v-container",
            {
              staticClass: "pa-0",
              class: `${this.status.color.split(" ")[0]}-border `,
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-0",
                  class: this.expand
                    ? `${this.status.color} white--text ${
                        this.status.color.split(" ")[0]
                      }-border-bottom`
                    : "black--text",
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-left clickable ma-0 no-space d-flex flex-column justify-center",
                      class: _vm.numClass,
                      attrs: { cols: "auto" },
                      on: { click: _vm.openTripRequest },
                    },
                    [
                      _vm.tripRequest.batchId
                        ? _c(
                            "div",
                            { staticClass: "text-subtitle-1 text-center" },
                            [
                              _vm._v(
                                "Batch #" + _vm._s(_vm.tripRequest.batchId)
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.tripRequest.localId
                        ? _c(
                            "div",
                            { staticClass: "text-subtitle-1 text-center" },
                            [_vm._v(_vm._s(_vm.tripRequest.localId))]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "text-h5 text-center font-weight-bold",
                          attrs: {
                            "data-cy": `trip-request-id-${_vm.tripRequest.id}`,
                          },
                        },
                        [_vm._v(" #" + _vm._s(_vm.tripRequest.id) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "icon-wrapper" },
                        [
                          _vm.tripRequest.recurrence &&
                          _vm.tripRequest.recurrence.length
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-autorenew ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    950989114
                                  ),
                                },
                                [_c("span", [_vm._v("Recurring Trip")])]
                              )
                            : _vm._e(),
                          _vm.tripRequest.dropoffOnly ||
                          _vm.tripRequest.pickupOnly
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " mdi-map-marker-alert-outline "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1762373603
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.tripRequest.pickupOnly
                                          ? "Pickup"
                                          : "Dropoff"
                                      ) + " only trip"
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.tripRequest.splitTrip
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-swap-horizontal ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    91937080
                                  ),
                                },
                                [_c("span", [_vm._v("Split trip")])]
                              )
                            : _vm._e(),
                          _vm.wasRescheduled.show
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-calendar ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2306396588
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.wasRescheduled.message)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.tripRequest.awayForLunch &&
                          _vm.tripRequest.needLunch
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-food-apple ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2859058681
                                  ),
                                },
                                [_c("span", [_vm._v("Students need lunches")])]
                              )
                            : _vm._e(),
                          _vm.tripRequest.haveHealthConcerns
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-medical-bag ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3072156316
                                  ),
                                },
                                [_c("span", [_vm._v("Health concerns")])]
                              )
                            : _vm._e(),
                          _vm.tripRequest.outOfState
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-weather-night ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4290411031
                                  ),
                                },
                                [_c("span", [_vm._v("Overnight trip")])]
                              )
                            : _vm._e(),
                          _vm.tripRequest.needExternalTransportation
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-train-car ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    83346147
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Needs external transportation"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.tripRequest.pendingDrivers
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-account-alert ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2379814684
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Trip is pending driver(s)"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.tripRequest.pendingVehicles
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-bus-alert ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4168203673
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v("Trip is pending vehicle(s)"),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.tripRequest.hasAttachment
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-paperclip ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2365968318
                                  ),
                                },
                                [_c("span", [_vm._v("Trip has attachment(s)")])]
                              )
                            : _vm._e(),
                          _vm.tripRequest.actualTimeTBD
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class: _vm.expand
                                                      ? `${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[0]
                                                        }--text text--${
                                                          _vm.status.color.split(
                                                            " "
                                                          )[1]
                                                        } mx-1`
                                                      : `white--text mx-1`,
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " mdi-clock-alert-outline "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3283796728
                                  ),
                                },
                                [_c("span", [_vm._v("Actual Time TBD")])]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.expand
                    ? [
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-4 no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Requester")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tripRequest.submittedUser &&
                                      _vm.usersById[
                                        _vm.tripRequest.submittedUser
                                      ]
                                      ? _vm.usersById[
                                          _vm.tripRequest.submittedUser
                                        ].displayName
                                      : "Legacy Requester"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Submitted")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tripRequest.submittedTimestamp
                                      ? _vm.getDisplayDate(
                                          _vm.fromUnixTime(
                                            _vm.tripRequest.submittedTimestamp
                                          )
                                        )
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("# Adults / Students")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                _vm._s(_vm.tripRequest.totalAdults) +
                                  " / " +
                                  _vm._s(_vm.tripRequest.totalStudents)
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Grade(s)")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(_vm._s(_vm.grades)),
                            ]),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "v-col",
                          {
                            staticClass: "ml-2 no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Leave / Return")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                _vm._s(_vm.leaveDate) +
                                  " @ " +
                                  _vm._s(_vm.leaveTime)
                              ),
                            ]),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                _vm._s(_vm.returnDate) +
                                  " @ " +
                                  _vm._s(_vm.returnTime)
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Location")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(_vm._s(_vm.locationName)),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Destination")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(_vm._s(_vm.destinationName)),
                            ]),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "no-space",
                            attrs: { cols: "12", md: "2" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-overline font-weight-bold" },
                              [_vm._v("Trip Type / Event(s)")]
                            ),
                            _c("div", { staticClass: "text-subtitle-1" }, [
                              _vm._v(
                                _vm._s(_vm.tripType) +
                                  " / " +
                                  _vm._s(_vm.tripEvents)
                              ),
                            ]),
                          ]
                        ),
                      ],
                  _c(
                    "v-col",
                    { staticClass: "no-space", attrs: { cols: "12", md: "2" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-overline font-weight-bold" },
                        [_vm._v("Status")]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", disabled: !_vm.status.tooltip },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      _vm._b(
                                        { staticClass: "text-subtitle-1" },
                                        "div",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm.tripRequest.approval.approved
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.expand
                                                  ? "white"
                                                  : "success",
                                              },
                                            },
                                            [_vm._v(" mdi-check-circle ")]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(_vm.status.display) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.status.tooltip) },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "text-subtitle-1" },
                        [
                          !_vm.tripRequest.numVehicles ||
                          (_vm.tripRequest.canAssign &&
                            _vm.tripRequest.assignmentsComplete)
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: _vm.expand ? "white" : "success",
                                  },
                                },
                                [_vm._v(" mdi-check-circle ")]
                              )
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.assignmentStatus) + " "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto px-0" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.handleExpand },
                        },
                        [
                          _c(
                            "v-icon",
                            { class: _vm.expand ? "white--text" : "" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.expand
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.hideQuickActions
                    ? _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.isAdmin ||
                                _vm.tripRequest.approval.history.length,
                              expression:
                                "isAdmin || tripRequest.approval.history.length",
                            },
                          ],
                          attrs: { cols: "auto px-0" },
                        },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                class: _vm.expand
                                                  ? "white--text"
                                                  : "",
                                              },
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2881235761
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.tripRequest.auditHistory.length,
                                          expression:
                                            "tripRequest.auditHistory.length",
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.showHistory()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-history")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Audit History"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm.tripRequest.status == 1
                                    ? _c(
                                        "v-list-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.me.is.superAdmin ||
                                                _vm.me.is.transportationAdmin ||
                                                _vm.me.is.limitedAdmin,
                                              expression:
                                                "me.is.superAdmin || me.is.transportationAdmin || me.is.limitedAdmin",
                                            },
                                          ],
                                          on: {
                                            click: function ($event) {
                                              return _vm.reschedule()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    "mdi-calendar-arrow-right"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Reschedule"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-list-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.canCreateDuplicate,
                                          expression: "canCreateDuplicate",
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.createDuplicate()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-content-copy")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Duplicate"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.me.is.superAdmin ||
                                            _vm.me.is.transportationAdmin ||
                                            _vm.me.is.limitedAdmin,
                                          expression:
                                            "me.is.superAdmin || me.is.transportationAdmin || me.is.limitedAdmin",
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.showChangeSubmitter()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-account-convert")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Change Submitter"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showDownloadPermissionSlip,
                                          expression:
                                            "showDownloadPermissionSlip",
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.permissionSlip()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-download")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Permission Slip"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showCancelButton,
                                          expression: "showCancelButton",
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancel()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-cancel")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Cancel"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showReinstanteButton,
                                          expression: "showReinstanteButton",
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.reinstate()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-restart")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-list-item-title", [
                                        _vm._v("Reinstate"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm.expand ? _c("v-divider") : _vm._e(),
              _c(
                "v-expand-transition",
                [
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.expand,
                          expression: "expand",
                        },
                      ],
                      staticClass: "ma-0",
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "px-2" },
                            [
                              _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                                _c("div", { staticClass: "text-overline" }, [
                                  _vm._v("Leave"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "text-h5 font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.leaveDate))]
                                ),
                                _c("div", { staticClass: "text-subtitle-1" }, [
                                  _vm._v(_vm._s(_vm.leaveTime)),
                                ]),
                                _c("div", { staticClass: "text-overline" }, [
                                  _vm._v("Return"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "text-h5 font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.returnDate))]
                                ),
                                _c("div", { staticClass: "text-subtitle-1" }, [
                                  _vm._v(_vm._s(_vm.returnTime)),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "no-space",
                                  attrs: { cols: "12", md: "8" },
                                },
                                [
                                  _c("div", { staticClass: "text-overline" }, [
                                    _vm._v("Route"),
                                  ]),
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "py-0",
                                      attrs: { flat: "" },
                                    },
                                    _vm._l(_vm.route, function (stop, i) {
                                      return _c(
                                        "v-list-item",
                                        { key: i, staticClass: "pl-0 py-0" },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c("v-icon", [
                                                _vm._v(_vm._s(stop.icon)),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(stop.name)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-4 no-space",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Trip Type")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.tripType)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Trip Event(s)")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.tripEvents)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Owner")]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                disabled: !_vm.vehicleOwner.email,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          _vm._b(
                                            { staticClass: "text-subtitle-1" },
                                            "div",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.vehicleOwner.display) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.vehicleOwner.email),
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Funding Source(s)")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.fundingSources)),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-4 no-space",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Wheelchair Lift")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.tripRequest.needSpecialNeedsVehicle
                                  ? "Yes"
                                  : "No"
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Special Equipment")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.specEq)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Special Indicators")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.specIns)),
                          ]),
                          _vm.tripRequest.needSubstitute
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "text-overline font-weight-bold amber--text text--darken-2",
                                },
                                [_vm._v(" Substitute Needed ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-4 no-space",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("# of Vehicles")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.vehicles)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Bid ID")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v("-"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Zone")]
                          ),
                          _c("div", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.zone)),
                          ]),
                        ]
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-4 no-space",
                          attrs: { cols: "12", md: "2" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-overline font-weight-bold" },
                            [_vm._v("Assignments")]
                          ),
                          _vm.assignments && _vm.assignments.length
                            ? [
                                _vm._l(
                                  _vm.assignments,
                                  function (assignment, i) {
                                    return [
                                      i < 2
                                        ? _c(
                                            "v-list",
                                            {
                                              key: assignment.id,
                                              staticClass: "py-0",
                                              attrs: { flat: "", dense: "" },
                                            },
                                            [
                                              assignment.vehicleId ||
                                              assignment.vehicle
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass: "pl-0 py-0",
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-icon",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-bus"),
                                                          ]),
                                                          assignment.leg == 1
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold-circle"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          assignment.leg == 2
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "blue",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-left-bold-circle"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        {
                                                          staticClass:
                                                            "py-0 my-0",
                                                        },
                                                        [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                disabled:
                                                                  !_vm.getVehicleInfo(
                                                                    assignment
                                                                  ).tooltip,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "v-list-item-title",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.getVehicleInfo(
                                                                                      assignment
                                                                                    )
                                                                                      .display
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-block",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.getVehicleLocation(
                                                                                          assignment.vehicleId
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      _vm.getVehicleInfo(
                                                                        assignment
                                                                      ).tooltip
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              assignment.driverId ||
                                              assignment.driver
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass: "pl-0 py-0",
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-icon",
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-account"
                                                            ),
                                                          ]),
                                                          assignment.leg == 1
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "green",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-right-bold-circle"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          assignment.leg == 2
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "blue",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-arrow-left-bold-circle"
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item-content",
                                                        {
                                                          staticClass:
                                                            "py-0 my-0",
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getDriverInfo(
                                                                    assignment
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      i + 1 < _vm.assignments.length
                                        ? _c("v-divider", {
                                            key: assignment.id + 100,
                                          })
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                                _vm.assignments.length > 2
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "my-2 font-weight-medium",
                                      },
                                      [
                                        _vm._v(
                                          "+" +
                                            _vm._s(_vm.assignments.length - 2) +
                                            " more"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : !_vm.tripRequest.permissions.canAssign
                            ? _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v("Cannot assign yet"),
                              ])
                            : _vm.tripRequest.numVehicles
                            ? _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v("No assignments yet"),
                              ])
                            : _c("div", { staticClass: "text-subtitle-1" }, [
                                _vm._v("No assignments needed"),
                              ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }